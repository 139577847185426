import { BiChevronDown } from "react-icons/bi"
import "./css/Header.css"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

const Header = ({ agentDetails, isOnline }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    const navigate = useNavigate()

    const handleLogout = () => {
        localStorage.removeItem("ca-token")
        navigate("/sign-in")
    }

    console.log({ isOnline })

    return (
        <div className="_6usd">
            <div className="unaged-sop"></div>
            {agentDetails === undefined ? (
                <div className="pured-are">Loading...</div>
            ) : (
                <div className="pured-are" onClick={() => setIsMenuOpen(prev => !prev)}>
                    {isMenuOpen ? <div onClick={e => e.stopPropagation()} className="taoism-mess">
                        <ul>
                            <li onClick={() => handleLogout()}>Logout</li>
                        </ul>
                    </div> : undefined}
                    <div className="clipping-word">
                        <div className={isOnline ? "online-dot" : "online-dot red"}></div>
                    </div>
                    <div className="profile-box">
                        <span>{agentDetails.full_name ? agentDetails.full_name[0] : "A"}</span>
                    </div>
                    <div className="mycelium-melt">
                        <span>{agentDetails.full_name}</span>
                        <span>{agentDetails.email}</span>
                    </div>
                    <div className="manliest-aim">
                        <BiChevronDown />
                    </div>
                </div>
            )}
        </div>
    )
}

export default Header
