import './App.css'
import Home from './pages/Home'
import { BrowserRouter, Routes, Route } from "react-router-dom"
import LandingPage from "./pages/LandingPage"
import Login from "./pages/Login"
import Register from "./pages/Register"

function App() {
	return (
		<BrowserRouter>
			<Routes>
				{/* <Route path="/" element={<LandingPage />}>
					<Route path="/sign-in" element={<Login />} />
					<Route path="/sign-up" element={<Register />} />
				</Route> */}
				<Route path="/sign-in" element={<Login />} />
				<Route path="/" element={<Login />} />

				<Route path="/dashboard" element={<Home />} />
			</Routes>
		</BrowserRouter>
	)
}

export default App
