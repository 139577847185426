import axios from "axios"
import api from "../config/backend"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Button, Stack, TextField, Typography } from "@mui/material"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import logo from '/logo.png';


const Login = () => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const navigate = useNavigate()

    const handleLogin = async () => {
        const { data, status } = await axios.post(`${api}/Agent/sign-in`, { email, password })

        if (data.success) {
            localStorage.setItem("ca-token", data.token)
            navigate("/dashboard")
            toast.success(data.message);
        }
        else {
            console.log(data.message);
            toast.error(data.message);
        }
    }

    return (
        <>
            {/* <div style={{ margin: "20px" }}>
                <div style={{ fontSize: "14px", color: "red" }}>Note: This page is not meant to be tested</div>

                <div style={{ display: "flex", flexDirection: "column", gap: "10px", marginTop: "20px", width: "400px" }}>
                    <input placeholder="Please enter e-mail" value={email} onChange={e => setEmail(e.target.value)} />
                    <input placeholder="Please enter password" type="password" value={password} onChange={e => setPassword(e.target.value)} />
                    <button style={{ width: "100px" }} onClick={() => handleLogin()}>Login</button>
                </div>
            </div> */}
            <>
                <Stack sx={{ width: '100%', height: '100vh', backgroundColor: '#ffffff', alignItems: 'center', justifyContent: 'center' }}>
                    <Stack sx={{ width: '40%', backgroundColor: '#f6f6f6', padding: '50px', gap: '20px', borderRadius: '20px', alignItems: 'center', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.5)' }}>
                        <img src="/logo.png" alt='LOGO' width={'200px'} />

                        <Typography variant='h5' sx={{ fontSize: '22px', fontWeight: 700, color: '#009999' }}>FNB Connect Whatsapp Agent Login</Typography>
                        <Stack sx={{ width: '-webkit-fill-available', gap: '20px' }}>
                            <TextField id="outlined-basic" label="Email" placeholder="Please enter e-mail" variant="outlined" type='email' value={email} onChange={e => setEmail(e.target.value)} />
                            <TextField id="outlined-basic" label="Password" placeholder="Please enter password" variant="outlined" type='password' value={password} onChange={e => setPassword(e.target.value)} />
                            <Button variant="contained" type='submit' sx={{ backgroundColor: '#009999' }} onClick={() => handleLogin()}>Login</Button>
                        </Stack>
                    </Stack>
                </Stack>
            </>
        </>
    )
}

export default Login
